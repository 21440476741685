


























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import CRRNFD from '../components/PickupItem/CRRNFD/index.vue'
import CRROTW from '../components/PickupItem/CRROTW/index.vue'
import DOX from '../components/PickupItem/DOX/index.vue'
import CRRPIC from '../components/PickupItem/CRRPIC/index.vue'
import CRRDON from '../components/PickupItem/CRRDON/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconSync from '@/app/ui/assets/icon_sync.vue'
import controller from '@/app/ui/controllers/PickupController'
import {
  LocalStorage,
  PICKUP_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { Pickup } from '@/domain/entities/Pickup'
import Loading from '@/app/ui/components/Loading/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess.vue'
import DropdownMultiSelect from '../components/DropdownMultiSelect/index.vue'
import ModalExportLog from '../components/Modals/ModalExportLog.vue'
import ModalExportCRRNFD from '../components/Modals/ModalExportCRRNFD.vue'

@Component({
  components: {
    Button,
    CRRNFD,
    CRROTW,
    DOX,
    CRRPIC,
    CRRDON,
    LoadingOverlay,
    IconSync,
    Loading,
    TextInput,
    DropdownSelect,
    ModalSuccess,
    DropdownMultiSelect,
    ModalExportLog,
    ModalExportCRRNFD
  },
})
export default class PickupDashboardPage extends Vue {
  controller = controller
  firstFetchComplete = false

  modalSuccessCRRSRCVisible = false
  showModalExportLog = false
  showModalExportCRRNFD = false

  parameters = {
    page: 1,
    search: '',
    shipmentType: [],
  }

  shipmentTypeOptions = [
    {
      label: 'Delivery Order (DO)',
      value: 'delivery_order',
    },
    {
      label: 'Marketplace (MP)',
      value: 'marketplace',
    },
    {
      label: 'Customer Apps (CA)',
      value: 'customer',
    },
    {
      label: 'Corporate',
      value: 'corporate',
    },
    {
      label: 'COD Balikan',
      value: 'cod_return'
    }
  ]

  created(): void {
    this.fetchPickups(true)
  }

  mounted(): void {
    this.setObserver()
  }

  private setObserver(): void {
    const el = this.$el.getElementsByClassName('end-of-page')[0]

    if (el) {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void => {
          if (
            entries[0].isIntersecting &&
            !this.isLoading &&
            !this.isLastPage &&
            this.pickupData.length !== 0
          ) {
            this.parameters.page++
            this.fetchPickups(false)
          }
        },
        {
          root: document.getElementById('#app'),
          threshold: 1.0,
        }
      )

      observer.observe(el)
    }
  }

  private fetchPickups(reset: boolean): void {
    if (reset) this.parameters.page = 1

    const payload = {
      statusId: this.statusId,
      page: this.parameters.page,
      perPage: PICKUP_PAGINATION,
      dateFrom: dayjs()
        .subtract(1, 'week')
        .format('YYYY-MM-DD'),
      sortDirection: 'ASC',
      statusIds: this.$route.meta.additional.statusIds,
      refetch: false,
      sync: false,
      clear: false,
      reset: reset,
      keyword: this.parameters.search || undefined,
      shipmentType: <string | undefined>(
        this.parameters.shipmentType
          .map(
            type =>
              this.shipmentTypeOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
    }

    if (!this.parameters.search) {
      delete payload.keyword
    }

    if (!this.isShowShipmentTypeFilter) {
      delete payload.shipmentType
    }

    controller.getPickupList(payload)
  }

  private onFilter(): void {
    this.fetchPickups(true)
  }

  get isLastPage(): boolean {
    return this.totalPages === controller.paginationData.page
  }

  get totalPages(): number {
    return Math.ceil(
      Number(controller.paginationData.totalItem) /
        Number(controller.paginationData.perPage)
    )
  }

  get tableHeader(): string[] {
    const statusHeader: Record<string, string[]> = {
      CRRNFD: [
        'No.',
        'ORDER ID',
        'CUSTOMER',
        'PHONE',
        'STATUS',
        'DATE TIME',
        'DURATION',
        '',
      ],
      DOX: [
        'No.',
        'ORDER ID',
        'STT Actual',
        'CUSTOMER',
        'PHONE',
        'STATUS',
        'DATE TIME',
        'DURATION',
        '',
      ],
      CRROTW: [
        'No.',
        'ORDER ID',
        'CUSTOMER',
        'PHONE',
        'STATUS',
        'ADDRESS',
        'COURIER',
        'UPDATE',
        'DURATION',
        '',
      ],
      CRRPIC: [
        'No.',
        'ORDER ID',
        'CUSTOMER',
        'PHONE',
        'STATUS',
        'ADDRESS',
        'COURIER',
        'UPDATE',
        'DURATION',
        '',
      ],
      CRRDON: [
        'No.',
        'ORDER ID',
        'CUSTOMER',
        'STATUS',
        'COURIER',
        'CREATED',
        'UPDATED',
      ],
    }

    return statusHeader[this.$route.meta.additional.statusId as string]
  }

  get pickupData(): Pickup[] {
    return controller.pickupData
  }

  get statusId(): string {
    return this.$route.meta.additional.statusId as string
  }

  get pageTitle(): string {
    return this.$route.meta.additional.pageTitle
  }

  get downloadable(): boolean {
    return this.$route.meta.additional.download as boolean
  }

  get manualAssignee(): boolean {
    return this.$route.meta.additional.manualAssignee as boolean
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get isExportLoading(): boolean {
    return controller.isExportLoading
  }

  get isCRRNFDExportLoading(): boolean {
    return controller.isExportCRRNFDLoading
  }

  get latestSync(): string {
    if (!controller.latestSync) {
      return ''
    }

    return dayjs(controller.latestSync).format('DD-MM-YY HH:mm')
  }

  get isCRROTW(): boolean {
    return this.statusId === 'CRROTW'
  }

  get isCRRPIC(): boolean {
    return this.statusId === 'CRRPIC'
  }

  get isCRRDON(): boolean {
    return this.statusId === 'CRRDON'
  }

  get isShowShipmentTypeFilter(): boolean {
    return this.isCRROTW || this.isCRRPIC || this.isCRRDON
  }

  get isexportPickupListLoading(): boolean {
    return controller.isexportPickupListLoading
  }

  private handleExport() {
    const email = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    controller.exportPickup({
      statusId: this.statusId,
      email,
    })
  }

  private handleExportCRRNFD(): void {
    const params = {
      file_name: `CRRNFD-${Utils.formatDateWithIDLocale(
        dayjs(),
        'YYYYMMDDHHmss'
      )}`,
      date_from: Utils.formatDate(dayjs().subtract(1, 'week'), 'YYYY-MM-DD'),
      sort_direction: 'ASC',
    }

    controller.exportCRRNFD(params)
  }

  private handleExportPickupList(): void {
    const params = {
      file_name: `${this.statusId}-${Utils.formatDateWithIDLocale(
        dayjs(),
        'YYYYMMDDHHmss'
      )}`,
      date_from: Utils.formatDate(dayjs().subtract(1, 'week'), 'YYYY-MM-DD'),
      keyword: this.parameters.search || undefined,
      shipmentType: <string | undefined>(
        this.parameters.shipmentType
          .map(
            type =>
              this.shipmentTypeOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      statusId: this.statusId,
    }

    if (!this.parameters.search) {
      delete params.keyword
    }

    controller.exportPickupList(params)
  }

  private syncData() {
    this.fetchPickups(true)
  }

  private onSearch: () => void = Utils.debounce(() => {
    this.fetchPickups(true)
  }, 500)

  @Watch('controller.isForceCRRSRCSuccess')
  onIsForceCRRSRCSuccessChange(data: boolean): void {
    if (data) {
      this.modalSuccessCRRSRCVisible = true
      this.fetchPickups(true)
      this.controller.setIsForceCRRSRCSuccess(false)
    }
  }
}
