






































import Button from '@/app/ui/components/Button/index.vue'
import DateTimePickerV2, {
  IDateRangeValue,
} from '@/app/ui/components/DateTimePickerV2/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CloseLine from '@/app/ui/assets/close_line.vue'
import PickupController from '@/app/ui/controllers/PickupController'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Modal,
    DateTimePickerV2,
    Button,
    CloseLine
  },
})
export default class ModalExportLog extends Vue {
  @Prop({ default: false }) visible!: boolean

  controller = PickupController

  dateRange = <IDateRangeValue>{
    start: null,
    end: null
  }

  public get decideDisableExportLogButton(): boolean {
    return this.controller.isLoadingExportManualAssignLog || !this.dateRange?.start || !this.dateRange?.end
  }

  public exportManualAssignLog(): void {
    const payload = {
      fileName: `ManualAssignLog-${Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYYMMDDHHmss')}`,
      startDate: Utils.formatDateWithIDLocale(<string>this.dateRange?.start?.toISOString(), 'YYYY-MM-DD'),
      endDate: Utils.formatDateWithIDLocale(<string>this.dateRange?.end?.toISOString(), 'YYYY-MM-DD'),
    }

    this.controller.exportManualAssignLog(payload)
  }

  public resetValue(): void {
    this.dateRange = {
      end: null,
      start: null
    }
  }

  @Watch('controller.isSuccessExportManualAssignLog')
  onSuccessExportManualAssignLog(data: boolean) {
    if (data) {
      this.resetValue()
      this.$emit('cancel')
    }
  }
}
